import Mock from 'mockjs'

// 企业信息
export const coCardData = Mock.mock({
  'list|8': [
    {
      id: /\d{5,10}/,
      lookTime: '@natural(60, 999)',
      'title|+1': '@ctitle(5, 8)',
      'coName|+1': '@ctitle(15, 30)',
      'rzTag|1': ['天使轮', 'A轮', 'B轮', 'C轮', 'D轮', 'E轮', 'F轮', 'G轮'],
      'add|+1': '@city( prefix )',
      'hyTag|1': [
        '新材料',
        '人工智能',
        '工业4.0',
        '电子信息',
        '智能设备',
        '半导体',
      ],
      'logo|+1': '@image("77x77", "#50B347")',
      'logoBig|1': '@image("120x120", "#6F69AC")',
      lowPrice: '@natural(1000, 9999)',
      heightPrice: '@natural(10000, 99999)',
    },
  ],
})

// 人才库
export const cuCardData = Mock.mock({
  'list|9': [
    {
      id: /\d{5,10}/,
      cname: '@cname()',
      name: '@name()',
      lookTime: '@natural(60, 999)',
      'xlTag|1': ['专科', '本科', '硕士', '博士'],
      'msTag|2': ['兼职', '全职', '顾问', '产学研'],
      'title|+1': '@ctitle(5, 8)',
      'coName|+1': '@ctitle(15, 30)',
      // 'rzTag|1': ['天使轮', 'A轮', 'B轮', 'C轮', 'D轮', 'E轮', 'F轮', 'G轮'],
      // 'add|+1': '@city( prefix )',
      'hyTag|+2': [
        '新材料',
        '人工智能',
        '工业4.0',
        '电子信息',
        '智能设备',
        '半导体',
      ],
      'avatar|+1': '@image("100x100", "#4b5cc4")',
      'avatarBig|+1': '@image("110x110", "#4b5cc4")',
    },
  ],
})

// 赛事活动
export const matchCardData = Mock.mock({
  'list|8': [
    {
      id: '@id()',
      'title|1': '@ctitle(15, 30)',
      'desc|1': '@cparagraph()',
      'qyTag|2': ['场地政策', '人才计划', '创融投服务', '其他权益'],
      'price|1': '@float(100, 999, 2, 2)',
      'start|1': '@now()',
      'end|1': '@now()',
      'city|1': '@city()',
    },
  ],
})

// 政策文件
export const policyData = Mock.mock({
  'list|8': [
    {
      id: '@id()',
      'title|1': '@ctitle(20, 30)',
      'desc|1': '@cparagraph()',
      'day|1': '@date("dd")',
      'yearMonth|1': '@date("yyyy-MM")',
      'date|1': '@date("yyyy-MM-dd")',
      'poster|1': '@image("180x120", "#7868E6")',
      'count|1': '@natural(90, 100)',
    },
  ],
})

// 成果转化
export const resultData = Mock.mock({
  'list|15': [
    {
      id: '@id()',
      'title|1': '@ctitle(8, 15)',
      'typeTag|1': [
        '金融科技',
        '智慧城市',
        '物联网应用',
        '企业服务',
        '消费生活',
        '工具软件',
      ],
      'city|1': '@city( prefix )',
      'ptTag|1': ['Web', 'Android', 'iOS', 'WeChat', 'Other'],
      lowPrice: '@float(1000, 9999, 2, 2)',
      heightPrice: '@float(10000, 99999, 2, 2)',
      'count|1': '@natural(0, 10)',
      'poster|1': '@image("240x180", "#CCFFBD")',
    },
  ],
})

export const mallData = Mock.mock({
  'list|8': [
    {
      id: '@id()',
      'title|1': '@ctitle(5, 10)',
      'codeTag|1': [
        'Java开发',
        '.net',
        'Web开发',
        'Go开发',
        'C语言开发',
        'C++开发',
        '其他',
      ],
      'typeTag|1': [
        '智慧园区',
        '智能穿戴',
        '智能共享',
        '报警器',
        'AI人工智能',
        '摄像头',
      ],
      count: '@natural(60, 999)',
      'img|+1': '@image("260x183", "#8d4bbb")',
    },
  ],
})

// 企业访谈
export const talkData = Mock.mock({
  'list|8': [
    {
      id: '@id()',
      'title|1': '@ctitle(20, 30)',
      'desc|1': '@cparagraph()',
      'yearMonth|1': '@date("yyyy-MM-dd")',
    },
  ],

  img1: '@image("213x214", "#725e82")',
  img2: '@image("213x214", "#725e82")',
})

// 赛事列表
export const matchList = Mock.mock({
  'list|9': [
    {
      id: '@id()',
      'title|1': '@ctitle(20, 30)',
      'add|1': '@county(prefix)',
      'start|+1': '@now()',
      'end|+1': '@now()',
      'poster|1': '@image("390x230", "#00e09e")',
    },
  ],

  // img1: '@image("213x214", "#725e82")',
  // img2: '@image("213x214", "#725e82")',
})

// 投资者
export const investData = Mock.mock({
  'list|10': [
    {
      id: '@id()',
      cname: '@cname()',
      'title|+1': [
        '合伙人',
        '执行董事',
        '投资总监',
        '董事总经理',
        '创始合伙人',
      ],
      'coName|1': '@ctitle(15, 30)',
      'city|1': '@city()',

      'count|1': '@natural(90, 100)',
      'rzTag|1': ['天使轮', 'A轮', 'B轮', 'C轮', 'D轮', 'E轮', 'F轮', 'G轮'],
      // 'add|+1': '@city( prefix )',
      'hyTag|1': [
        '新材料',
        '人工智能',
        '工业4.0',
        '电子信息',
        '智能设备',
        '半导体',
      ],
      'avatar|+1': '@image("128x128", "#7C83FD")',
    },
  ],
})

console.log('mock', policyData)
